<template>
  <div class="task-brief pb-5" v-html="brief">
  </div>
</template>

<script>
export default {
  props: {
    brief: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.task-brief {
  font-size: 0.875rem;
  li {
    margin-bottom: 25px;
  }
}
</style>
