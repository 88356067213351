<template>
  <div class="test-tabs">
    <b-tabs>
      <b-tab active>
        <template #title> Overview </template>
        <TestOverview
          :overview="description"
          :test-started="!testDisabled"
          @confirmTest="$bvModal.show('test_prompt')"
        />
      </b-tab>

      <b-tab :disabled="testDisabled">
        <template #title> Task Brief <LockIcon v-if="testDisabled" /> </template>
        <TaskBrief :brief="brief" />
      </b-tab>

      <b-tab :disabled="testDisabled">
        <template #title>
          Submission
          <LockIcon v-if="testDisabled" />
        </template>
        <Submission :is-busy="isBusy" @submitted="promptSubmitAssessment" />
      </b-tab>
      <prompt-modal
        modal-id="test_prompt"
        title="Are sure you want to start?"
        ok-title="Continue"
        cancel-title="Cancel"
        @ok="startAssessment()"
      >
        <p class="m-fs-16 text-center">
          Starting this test means that you must finish it, and you will not be able to
          start the test over again unless you fail and a new date is scheduled for the
          retake.
        </p>
      </prompt-modal>

      <prompt-modal
        modal-id="submission_prompt"
        title="Are sure you want to submit?"
        ok-title="Continue"
        cancel-title="Cancel"
        @ok="submitAssessment"
      >
        <p class="m-fs-16 text-center">Once you submit you cannot make any changes.</p>
      </prompt-modal>
    </b-tabs>
  </div>
</template>

<script>
import TestOverview from "./TestOverview.vue";
import TaskBrief from "./TaskBrief.vue";
import Submission from "./Submission.vue";
import PromptModal from "@/components/PromptModal";
import LockIcon from "@/components/svg/LockIcon.vue";
export default {
  components: {
    TestOverview,
    TaskBrief,
    Submission,
    PromptModal,
    LockIcon,
  },
  props: {
    description: {
      type: String,
      required: true,
    },
    brief: {
      type: String,
      default: "",
    },
    testDisabled: {
      type: Boolean,
      default: true,
    },
    isBusy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: null,
    };
  },
  methods: {
    startAssessment() {
      this.$emit("started");
    },
    submitAssessment() {
      this.$emit("submitted", this.form);
    },
    promptSubmitAssessment(form) {
      this.form = form;
      this.$bvModal.show("submission_prompt");
    },
  },
};
</script>

<style lang="scss">
.test-tabs {
  .nav-tabs {
    .nav-link {
      color: $black-tint-3;
      font-size: 0.875rem;
      border: none;
      padding: 0.9rem;
      &.active {
        color: $primary-color;
        border: none;
        border-bottom: 4px solid $primary-color;
      }
      &:hover,
      &:focus {
        border: none;
        border-bottom: 4px solid $primary-color;
      }
    }
    .nav-items {
      &.show {
        .nav-link {
          border: none;
          border-bottom: 4px solid $primary-color;
        }
      }
    }
  }
  .tab-content {
    .tab-pane {
      padding: 20px 0;
    }
  }
}
</style>
