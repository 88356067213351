<template>
  <div class="submission pb-5">
    <div class="submission__task">
      <p>
        Please upload your task based on the information provided to you in the brief.
      </p>
    </div>
    <div class="submission__document-upload">
      <BoxUploader v-model="form.solution" class="mb-3" />
      <b-form-group
        label="Optional <If there is a document>"
        label-for="textarea-formatter"
        class="mb-0"
      >
        <b-form-textarea
          id="submission_details"
          v-model="form.submission_details"
          placeholder="Submission Details e.g github link or further explanation"
        ></b-form-textarea>
      </b-form-group>
      <div class="text-center">
        <b-button
          type="submit"
          variant="primary"
          class="test-overview__start-test mt-5 mb-5 min-w-200"
          :disabled="disableBtn"
          @click="submitAssessment()"
        >
          {{ isBusy ? 'Processing...' : 'Submit' }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import BoxUploader from "../BoxUploader.vue";
export default {
  components: {
    BoxUploader,
  },
  props: {
    isBusy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        solution: null,
        submission_details: "",
      },
    };
  },
  computed: {
    disableBtn() {
      return (this.form.solution === null && this.form.submission_details == '') || this.isBusy
    }
  },
  methods: {
    submitAssessment() {
      this.$emit('submitted', this.form)
    }
  }
};
</script>

<style lang="scss" scoped>
.submission {
  font-size: 0.875rem;
  li {
    margin-bottom: 25px;
  }
}
</style>
