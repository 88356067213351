<template>
  <div class="test-overview">
    <div v-html="overview"></div>
    <b-button
      v-if="!testStarted"
      type="submit"
      variant="primary"
      class="test-overview__start-test mt-5 mb-5 min-w-200"
      @click="confirmTest()"
    >
      Start Test
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    overview: {
      type: String,
      required: true,
    },
    testStarted: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    confirmTest() {
      this.$emit("confirmTest");
    },
  },
};
</script>

<style lang="scss" scoped>
.test-overview {
  font-size: 0.875rem;
  li {
    margin-bottom: 25px;
  }
  p {
    margin-bottom: 25px;
  }
  h4 {
    font-size: 1rem;
    color: $primary-color;
  }
}
</style>
