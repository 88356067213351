<template>
  <div class="test">
    <div class="dashboard-summary">
      <TestTimer
        :time="time"
        :duration="duration"
        :paused="paused"
      />
    </div>
    <div class="dashboard-content py-4">
      <div class="test__container p-4">
        <h2>Test Overview</h2>
        <hr />
        <TheTestTabs
          :test-disabled="paused"
          :description="description"
          :brief="brief"
          :is-busy="isBusy"
          @started="handleStart()"
          @submitted="handleSubmit"
        />
      </div>
    </div>
    <prompt-modal
      modal-id="test_end"
      title="Test has ended"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      ok-title="Continue Assessment"
      cancel-title="Back To Skills"
      @ok="continueAssessment()"
      @cancel="$router.push('/skills')"
    >
      <p class="m-fs-16 text-center">
        You may go back to the skills page or continue to the assessment details page to
        view your result.
      </p>
    </prompt-modal>
  </div>
</template>

<script>
import TheTestTabs from "@/components/skills/TheTestTabs.vue";
import TestTimer from "@/components/skills/TestTimer.vue";
import PromptModal from "@/components/PromptModal.vue";

import { mapState, mapActions, mapMutations } from "vuex";

export default {
  title: "Menaget - Test Assessment",
  components: {
    TheTestTabs,
    TestTimer,
    PromptModal,
  },
  data() {
    return {
      isBusy: true
    }
  },
  computed: {
    ...mapState({
      assessment: (state) => state.skill.assessment,
    }),
    description() {
      return this.assessment.ongoing_test?.description || this.assessment.latest_test?.description
    },
    paused() {
      return this.assessment.ongoing_test?.started_at === null;
    },
    brief() {
      return this.assessment.ongoing_test?.question || "";
    },
    time() {
      return this.assessment?.ongoing_test?.left_time || this.assessment?.latest_test?.left_time
    },
    duration() {
      return this.assessment?.ongoing_test?.duration || this.assessment?.latest_test?.duration
    }
  },
  watch: {
    assessment(assessment) {
      if (assessment.ongoing_test === null || assessment.wait_time > 0) {
        this.$bvModal.show("test_end");
      }
    },
  },
  created() {
    this.fetchAssessment(this.$route.params.id).then(() => {
      if (this.assessment.ongoing_test === null || this.assessment.wait_time > 0) {
        this.$store.dispatch("setErrorStatus", 404);
        this.$router.push("/404");
      }

      this.setPageInformation({
        title: `${this.assessment.skill.name} Skill Test Assessment`,
        description: this.assessment.skill.description,
      });

      this.subscribeToEvent(
        this.assessment?.ongoing_test?.skill_talent_id ||
          this.assessment?.latest_test?.skill_talent_id
      );
    })
    .finally(() => {
      this.isBusy = false
    })
  },
  methods: {
    ...mapActions({
      fetchAssessment: "skill/fetchAssessment",
      startTest: "skill/startTest",
      submitTest: "skill/submitTest",
    }),
    ...mapMutations({
      setPageInformation: "SET_PAGE_INFORMATION",
      setAssessment: "skill/SET_ASSESSMENT",
    }),
    handleStart() {
      this.startTest(this.$route.params.id);
    },
    subscribeToEvent(skillTalentId) {
      Echo.private(`App.Models.Skills.SkillTalent.${skillTalentId}`).listen(
        `.test.completed`,
        (e) => {
          this.setAssessment(e.assessment);
        }
      );
    },
    continueAssessment() {
      this.$router.push(`/skills/${this.$route.params.id}/assessment-details`);
    },
    handleSubmit(form) {
      this.isBusy = true
      const formData = new FormData()
      formData.append("submission_details", form.submission_details);

      if (form.solution !== null) {
        formData.append("solution", form.solution);
      }

      this.submitTest({id: this.$route.params.id, data: formData}).then(() => {
         //do something here maybe
      })
      .catch(() => {

      })
      .finally(() => {
         this.isBusy = false
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.test {
  h2 {
    color: $black-tint-3;
    font-size: 1.5rem;
    line-height: 2.625rem;
    font-weight: 600;
  }
  &__container {
    background-color: $body-bg;
  }
}
</style>
